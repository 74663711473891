html,
body,
#root {
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
}
* {
  box-sizing: border-box;
}
