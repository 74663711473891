body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 处理 vm 适配图片不显示问题 */
img {
  content: normal !important; 
}


iframe.webpack-dev-server-client-overlay {
  display: none !important;
}

.empty-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 50vh;
  font-size: 16px;
}

.loading-wrap {
  position: fixed;
  top: 16px;
  right: 16px;
}